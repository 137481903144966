/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsxImportSource @emotion/react */
import { themeLight } from '@digital-hig/theme-mui';
import { Global } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MainBgImageAttributes } from 'appConstants/image';
import BackgroundImage from 'common/components/BackgroundImage/BackgroundImage';
import globalStyles from 'common/styles/globalStyles';
import OptOutBanner from 'components/OptOutBanner/OptOutBanner';
import AppContext from 'context/appContext';
import I18nProvider from 'i18n/i18nProvider';
import { useEffect, useMemo, useState } from 'react';
import { isGlobalError } from 'utilities/commonUtils';
import { checkIfAnalyticsScriptLoaded } from 'utilities/tealiumAnalytics';
import { getInitialLocale } from 'utilities/uiLocalesUtils';
import { cardWrapperStyles, mainContainerStyles } from './MainWrapperStyles';

interface MainWrapperProps {
  children: React.ReactNode;
  blackBackground?: boolean;
  initialFetchingFlowStatus?: boolean;
}

const MainWrapper = ({ children, blackBackground, initialFetchingFlowStatus }: MainWrapperProps) => {
  /* initialFetchingFlowStatus is passed from index.tsx as true always.
   * As it's not getting passed from stories, using it to set globalError to false, so as to render background color/image accordingly
   */
  const [globalError, setGlobalError] = useState(!initialFetchingFlowStatus ? false : isGlobalError());
  const [uiLocale, setUiLocale] = useState(getInitialLocale());
  /*
   * Initializing fetchingFlowStatus using prop to make sure storybook stories don't have white background
   * Stories file don't pass initialFetchingFlowStatus so that doesn't affect the background of stories
   * index.ts from src passes true to MainWrapper so that the background is white while showing the LoadingSkeleton
   */
  const [showOptOutBanner, setShowOptOutBanner] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    clientID: '',
    status: '',
    applicationName: '',
  });

  const [fetchingFlowStatus, setFetchingFlowStatus] = useState(initialFetchingFlowStatus);
  const enableOptOutBanner = window.idp?.env?.constants?.ENABLE_OPT_OUT_BANNER;

  const appContextValue = useMemo(
    () => ({
      globalError,
      clientDetails,
      setClientDetails,
      setGlobalError,
      fetchingFlowStatus,
      setFetchingFlowStatus,
      setUiLocale,
      uiLocale,
      setShowOptOutBanner,
    }),
    [
      globalError,
      setGlobalError,
      setUiLocale,
      clientDetails,
      setClientDetails,
      fetchingFlowStatus,
      setFetchingFlowStatus,
      uiLocale,
      setShowOptOutBanner,
    ],
  );

  // Initialize Tealium Analytics and check if the google analytics script has loaded

  useEffect(() => {
    checkIfAnalyticsScriptLoaded();
  }, []);

  // If globalError is true OR if initial API call is in progress, don't show bg image

  const whiteBackground = globalError || fetchingFlowStatus;

  return (
    <AppContext.Provider value={appContextValue}>
      {/* @ts-ignore */}
      <ThemeProvider theme={createTheme(themeLight)}>
        <Global styles={globalStyles} />
        <I18nProvider locale={uiLocale}>
          <main css={mainContainerStyles(whiteBackground)}>
            {showOptOutBanner && enableOptOutBanner && <OptOutBanner />}
            {!(blackBackground || whiteBackground) && <BackgroundImage {...MainBgImageAttributes} />}
            <div css={cardWrapperStyles}>{children}</div>
          </main>
        </I18nProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default MainWrapper;
