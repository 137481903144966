/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import { EMAIL_ERROR_TO_ANALYTICS_TEXT_MAP, ERROR_CODES, ERROR_TYPE, PAGE_VIEWS } from 'appConstants/analytics';
import { AUTH_ACTIONS, AUTH_STATUS } from 'appConstants/auth';
import { UI_LOCALE_COOKIE } from 'appConstants/common';
import { EMAIL_MAX_LENGTH } from 'appConstants/email';
import { EMAIL_ERRORS } from 'appConstants/error';
import { HCAPTCHA_FLOWS } from 'appConstants/hCaptcha';
import { RESEND_STATUS } from 'appConstants/otp';
import { NAVIGATION_DIRECTIONS } from 'appConstants/routing';
import CardSkeleton from 'common/components/CardSkeleton/CardSkeleton';
import CustomInput from 'common/components/CustomInput/CustomInput';
import CustomLink from 'common/components/CustomLink/CustomLink';
import Divider from 'common/components/Divider/Divider';
import LoadingButton from 'common/components/LoadingButton/LoadingButton';
import { disabledLinkStyles, linkStyles } from 'common/styles/commonStyles';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailScreenBodyProps, EmailScreenFooterProps, EmailScreenProps } from 'types';
import { CustomLinkTrackingData } from 'types/Link';
import { getEventLocation } from 'utilities/commonUtils';
import { validateEmail } from 'utilities/errorUtils';
import { setResendDataInLocalStorage } from 'utilities/otpScreenUtils';
import { trackError } from 'utilities/tealiumAnalytics';
import { setUiLocaleCookie } from 'utilities/uiLocalesUtils';
import SubHeadingWithLink from '../SubHeadingWithLink/SubHeadingWithLink';
import TermsOfService from '../TermsOfService/TermsOfService';

const GetBody: React.FC<EmailScreenBodyProps> = ({
  disableForm,
  setDisableForm,
  formSubmitAction,
  showEmailInput,
  showTos,
  bodyText,
  pageFlow = '',
  handleFlowChange,
  handleErrorCallback,
}) => {
  const { authCallBack, userEmail, setUserEmail, flowType } = useContext(AuthContext);
  const [emailError, setEmailError] = useState<string>('');
  const [tosAccepted, setTosAccepted] = useState(false);
  const [tosError, setTosError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const errorLinkRef = useRef<string>();
  const errorLinkTrackingDataRef = useRef<CustomLinkTrackingData>();
  const { t } = useTranslation([authNamespaces.email, authNamespaces.common, authNamespaces.termsOfService]);

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const oldCursorPosition = e.target.selectionStart ? e.target.selectionStart : null;

    // Remove spaces from the value
    let valueWithoutSpace = value.replace(/\s+/g, '');

    // Limit email input to 320 characters
    if (valueWithoutSpace.length > EMAIL_MAX_LENGTH) {
      valueWithoutSpace = valueWithoutSpace.slice(0, EMAIL_MAX_LENGTH);
    }

    /*
     * If the value has spaces, update the input value to remove spaces
     * and update the cursor position to the correct position
     */
    const newCursorPosition = oldCursorPosition ? oldCursorPosition - (value.length - valueWithoutSpace.length) : null;

    // Store email input in context to make it available to prefill in create account flow if user clicks on 'Create Account' button
    setUserEmail(valueWithoutSpace);
    if (emailError) {
      setEmailError('');
    }

    // Update the cursor position back to the correct position as removing space will change the cursor position to the end of the input
    if (emailInputRef.current && newCursorPosition !== null) {
      const updateCursorPosition = () => {
        if (emailInputRef.current && emailInputRef.current.selectionStart && emailInputRef.current.selectionEnd) {
          emailInputRef.current.selectionStart = newCursorPosition;
          emailInputRef.current.selectionEnd = newCursorPosition;
        }
      };
      /**
       * Use requestAnimationFrame to update the cursor position after the input value has been updated
       */
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(updateCursorPosition);
      } else {
        /*
         * Use setTimeout to update the cursor position after the input value has been updated
         * if requestAnimationFrame is not supported
         */
        setTimeout(updateCursorPosition, 0);
      }
    }
  };

  const handleEmailError = (errorMessage: string) => {
    setEmailError(errorMessage);
    // Focus on input on error for better accessibility and screen reader support
    const emailInput = document.getElementById('userName') as HTMLInputElement;
    emailInput?.focus();
  };

  const onNext = async (e: React.FormEvent) => {
    e.preventDefault();
    const emailErrorMessage = showEmailInput && validateEmail(userEmail);
    if (emailErrorMessage) {
      const errorText = t(emailErrorMessage);
      trackError({
        error_code: ERROR_CODES.CLIENT_ERROR,
        error_reason: emailErrorMessage,
        error_message: t(emailErrorMessage, { lng: 'root' }), // make a function in utils
        error_type: ERROR_TYPE.CLIENT_SIDE_VALIDATION,
        error_location: getEventLocation(pageFlow, PAGE_VIEWS.EMAIL_SCREEN),
      });
      handleEmailError(errorText);
    }
    const tosNotAccepted = showTos && !tosAccepted;
    setTosError(tosNotAccepted);
    if (tosNotAccepted || emailErrorMessage) {
      if (!emailErrorMessage) {
        // Focus on input on error for better accessibility and screen reader support
        const invalidCheckbox = document.querySelector('input[type="checkbox"]:not([checked])') as HTMLInputElement;
        invalidCheckbox?.focus();
      }
      return;
    }

    setDisableForm(true);
    setShowLoader(true);
    const payload = showTos ? { email: userEmail, acceptTOS: tosAccepted } : { email: userEmail };
    const { error, response, responseStatus } = await authCallBack(payload, formSubmitAction);
    if (error) {
      setShowLoader(false);
      setDisableForm(false);
      // Store message which contains account recovery link in case of account queued for deletion error
      if (error.code === EMAIL_ERRORS.ACCOUNT_QUEUED_FOR_DELETION) {
        const errorLinkTrackingData = {
          linkLocation: getEventLocation(pageFlow, PAGE_VIEWS.EMAIL_SCREEN),
          linkText: EMAIL_ERROR_TO_ANALYTICS_TEXT_MAP[error.code],
          linkUrl: '#',
        };
        errorLinkTrackingDataRef.current = errorLinkTrackingData;
        errorLinkRef.current = error.message;
      }
      const errorMessage = handleErrorCallback(error, responseStatus ?? ERROR_CODES.SERVER_ERROR);
      if (errorMessage) {
        handleEmailError(errorMessage);
      }
      if (window.location.origin.includes('-dev')) {
        throw new Error('Alert test');
      }
    }

    if (response?.status === AUTH_STATUS.EMAIL_OTP_REQUIRED) {
      // Temp fix to handle race condition when clearEmail is triggered before sendOtp completes
      // Should be removed once loader screen is implemented for Hcaptcha flows.
      window.sendingOtp = true;
      document.dispatchEvent(new CustomEvent('UPDATE_SENDING_OTP_STATUS'));

      // This is to handle non CCPA flows case when user email is not verified
      // Handle sending the OTP for email verification for sign in and create account flow,
      // as its an extra step to be done when user moves from email screen to OTP screen
      await authCallBack(
        null,
        AUTH_ACTIONS.SEND_OTP,
        '',
        NAVIGATION_DIRECTIONS.FORWARD,
        false,
        HCAPTCHA_FLOWS.EMAIL_VERIFICATION,
      );
      setResendDataInLocalStorage({
        status: RESEND_STATUS.TIMER,
        flowType,
      });
    } else if (response?.status === AUTH_STATUS.RESUME) {
      setUiLocaleCookie(UI_LOCALE_COOKIE, response?.requestContext?.oidcUiLocales ?? '');
    }
  };

  const onErrorTextLinkClick = async () => {
    setDisableForm(true);
    const response = await handleFlowChange();
    if (response?.error) {
      setDisableForm(false);
    }
  };

  return (
    <form noValidate onSubmit={onNext} className="form-container">
      {bodyText && (
        <Typography id="email-screen-subheading" variant="short-copy-small">
          {t(bodyText)}
        </Typography>
      )}
      {showEmailInput && (
        <CustomInput
          id="userName"
          label={t('EMAIL', { ns: 'common' })}
          value={userEmail}
          name="email"
          errorMessage={emailError}
          disabled={disableForm}
          onChange={handleEmailInputChange}
          onErrorTextLinkClick={onErrorTextLinkClick}
          errorLinkTarget={errorLinkRef.current}
          target="_self"
          autoFocus
          fullWidth
          inputRef={emailInputRef}
          errorLinkTrackingData={errorLinkTrackingDataRef.current}
        />
      )}
      <TermsOfService
        disableForm={disableForm}
        tosError={tosError}
        onTosError={setTosError}
        onTosChange={setTosAccepted}
        showTos={showTos}
        showMarketingOptIn={false}
        pageFlow={getEventLocation(pageFlow, PAGE_VIEWS.EMAIL_SCREEN)}
      />
      <LoadingButton
        isDisabled={disableForm}
        showLoader={showLoader}
        text={t('NEXT', { ns: 'common' })}
        buttonType="submit"
        id="verify_user_btn"
        buttonLocation={getEventLocation(pageFlow, PAGE_VIEWS.EMAIL_SCREEN)}
      />
    </form>
  );
};

const GetFooter: React.FC<EmailScreenFooterProps> = ({
  disableForm,
  setDisableForm,
  footerText,
  footerLinkText,
  footerLinkOnClick,
}) => {
  const onLinkClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setDisableForm(true);
    const response = await footerLinkOnClick();
    if (response?.error) {
      setDisableForm(false);
    }
  };

  return (
    <>
      <Divider sx={{ mb: 6 }} />
      <Typography variant="short-copy-small">
        {footerText}{' '}
        <CustomLink cssStyles={[linkStyles, disableForm ? disabledLinkStyles : '']} href="#" onClick={onLinkClick}>
          {footerLinkText}
        </CustomLink>
      </Typography>
    </>
  );
};

const EmailScreen: React.FC<EmailScreenProps> = ({
  heading,
  subHeading,
  showEmailInput = true,
  formSubmitAction,
  bodyText = '',
  showTos = false,
  showFooter,
  footerText,
  pageFlow = '',
  footerLinkText,
  handleFlowChange,
  handleErrorCallback,
}) => {
  const [disableForm, setDisableForm] = useState(false);
  return (
    <CardSkeleton
      heading={heading}
      subHeading={subHeading ? <SubHeadingWithLink headingText={subHeading} /> : <></>}
      body={
        <GetBody
          bodyText={bodyText}
          showEmailInput={showEmailInput}
          disableForm={disableForm}
          setDisableForm={setDisableForm}
          formSubmitAction={formSubmitAction}
          showTos={showTos}
          handleFlowChange={handleFlowChange}
          handleErrorCallback={handleErrorCallback}
          pageFlow={pageFlow}
        />
      }
      footerType="medium"
      footer={
        showFooter ? (
          <GetFooter
            disableForm={disableForm}
            setDisableForm={setDisableForm}
            footerText={footerText}
            footerLinkText={footerLinkText}
            footerLinkOnClick={handleFlowChange}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default EmailScreen;
