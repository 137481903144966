/** Map the languages defined in json file to the code being sent from the UI */
const LanguageCodeMappings: { [key: string]: string } = {
  en: 'root',
  de: 'de',
  de_de: 'de',
  cs: 'cs',
  cs_cz: 'cs',
  da: 'da',
  da_dk: 'da',
  es: 'es',
  es_es: 'es',
  fi: 'fi',
  fi_fi: 'fi',
  fr: 'fr',
  fr_fr: 'fr',
  fr_ca: 'fr',
  hu: 'hu',
  hu_hu: 'hu',
  it: 'it',
  it_it: 'it',
  ja: 'ja',
  ja_jp: 'ja',
  ko: 'ko',
  ko_kr: 'ko',
  nb: 'no',
  no: 'no',
  no_no: 'no',
  nl: 'nl',
  nl_nl: 'nl',
  pl: 'pl',
  pl_pl: 'pl',
  pt: 'pt-PT',
  pt_br: 'pt-BR',
  pt_pt: 'pt-PT',
  ru: 'root',
  ru_ru: 'root',
  sv: 'sv',
  sv_se: 'sv',
  tr: 'tr',
  tr_tr: 'tr',
  zh: 'zh-CN',
  zh_hk: 'zh-HK',
  zh_cn: 'zh-CN',
  zh_tw: 'zh-TW',
  zh_mo: 'zh-HK',
  zh_hant: 'zh-TW',
  zh_hans: 'zh-CN',
};

const BASE_LOCALES: { [key: string]: string } = {
  ko: 'kr',
  ja: 'jp',
  de: 'de',
  it: 'it',
  nl: 'nl',
  br: 'br',
  pl: 'pl',
  es: 'es',
  fr: 'fr',
  fr_CA: 'fr',
  pt: 'pt',
  pt_BR: 'pt',
  pt_PT: 'pt',
  zh: 'zh-hans',
  zh_CN: 'zh-hans',
  zh_HK: 'zh-hans',
  zh_TW: 'zh-hans',
};

const LOCALE_MAP_PRIVACY_STATEMENT = BASE_LOCALES;

const LOCALE_MAP_TERMS_OF_USE: { [key: string]: string } = {
  ...BASE_LOCALES,
  cs: 'cs',
  zh: 'cn',
  zh_CN: 'cn',
  zh_HK: 'cn',
  zh_TW: 'cn',
  pt: 'br',
  pt_BR: 'br',
  pt_PT: 'br',
};

const LOCALE_MAP_ESSO_ERRORS: { [key: string]: string } = {
  de: 'DEU',
  fr: 'FRA',
  fr_CA: 'FRA',
  es: 'ESP',
  ja: 'JPN',
  ja_JP: 'JPN',
  pt: 'PTB',
  pt_BR: 'PTB',
  pt_PT: 'PTB',
  zh: 'CHS',
  zh_CN: 'CHS',
  zh_HK: 'CHS',
  zh_TW: 'CHS',
};

const LOCALE_MAP_CONTACT_SUPPORT_PASSWORD_POLICY_AUTODESK_HOMEPAGE: { [key: string]: string } = {
  ...BASE_LOCALES,
  no: 'no',
  fi: 'fi',
  tr: 'tr',
  fr_CA: 'ca-fr',
  zh: 'cn',
  zh_CN: 'cn',
  zh_HK: 'cn',
  zh_TW: 'cn',
};

export {
  LOCALE_MAP_PRIVACY_STATEMENT as BASE_LOCALES,
  LOCALE_MAP_CONTACT_SUPPORT_PASSWORD_POLICY_AUTODESK_HOMEPAGE,
  LOCALE_MAP_ESSO_ERRORS,
  LOCALE_MAP_TERMS_OF_USE,
  LanguageCodeMappings,
};
