/** @jsxImportSource @emotion/react */
import SvgError from '@digital-hig/icon/lib/esm/display-icons/error';
import Typography from '@digital-hig/typography';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import SvgIcon from '@mui/material/SvgIcon';
import { AUTH_STATUS_BASED_PAGE_VIEWS, ERROR_CODES, ERROR_TYPE, EVENT_TEXT } from 'appConstants';
import { COLLAPSE_TRANSITION_TIMEOUT } from 'appConstants/common';
import { TERMS_OF_SERVICE_TYPE } from 'appConstants/tosAndCommunication';
import CustomLink from 'common/components/CustomLink/CustomLink';
import { commonLinkStyles, disabledLinkStyles } from 'common/styles/commonStyles';
import AppContext from 'context/appContext';
import AuthContext from 'context/authContext';
import DOMPurify from 'dompurify';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CustomCheckboxProps, ExpandCollapseLinkProps, GetLabelForCheckboxProps, TermsOfServiceProps } from 'types';
import { getEventLocation, removeHtmlTags } from 'utilities/commonUtils';
import { trackClick, trackError } from 'utilities/tealiumAnalytics';
import {
  checkIfAllRequiredTosAccepted,
  getCheckBoxListWithInitialValues,
  getUpdatedTosValues,
  handleMarketOptInLinkClick,
  updateAllTosErrorValues,
} from 'utilities/tosUtils';
import { updateUrlWithLocale } from 'utilities/uiLocalesUtils';
import { checkboxLabelStyles, errorTextStyles, marketingOptinLinkStyles } from './TermsOfServiceStyles';

const ExpandCollapseLink = ({ disableForm, isTosExpanded, handleExpandCollapse }: ExpandCollapseLinkProps) => {
  const { flowStatus, flowType } = useContext(AuthContext);
  const { t } = useTranslation([authNamespaces.termsOfService]);
  const labelText = t(isTosExpanded ? 'SHOW_LESS' : 'SHOW_MORE');

  return (
    <CustomLink
      onClick={handleExpandCollapse}
      cssStyles={[commonLinkStyles, disableForm ? disabledLinkStyles : '']}
      ariaLabel={isTosExpanded ? t('SHOW_LESS_LABEL') : t('SHOW_MORE_LABEL')}
      tracking={{
        linkText: labelText,
        linkLocation: getEventLocation(flowType, AUTH_STATUS_BASED_PAGE_VIEWS[flowStatus.status]?.page_content_name),
      }}
      href="#"
    >
      {labelText}
    </CustomLink>
  );
};

const GetLabelForCheckbox = ({ tosContent, disableForm, tosError, optInLabel }: GetLabelForCheckboxProps) => {
  const { flowStatus, flowType } = useContext(AuthContext);
  const { uiLocale } = useContext(AppContext);
  const [isTosExpanded, setIsTosExpanded] = useState(false);
  const { t } = useTranslation([tosContent.nameSpace, authNamespaces.termsOfService, authNamespaces.common]);

  // Create a ref for the wrapper div
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Attach event listener to the wrapper div omount
  useEffect(() => {
    const wrapperDiv = wrapperRef.current;

    if (wrapperDiv) {
      wrapperDiv.addEventListener('click', (event) => handleMarketOptInLinkClick(event, flowType, flowStatus.status));
    }

    // Cleanup function to remove the event listener
    return () => {
      if (wrapperDiv) {
        wrapperDiv.removeEventListener('click', (event) =>
          handleMarketOptInLinkClick(event, flowType, flowStatus.status),
        );
      }
    };
  }, []);

  const handleExpandCollapse = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsTosExpanded(!isTosExpanded);
    trackClick({
      link_location: getEventLocation(flowType, AUTH_STATUS_BASED_PAGE_VIEWS[flowStatus.status].page_content_name),
      link_text: `${t(isTosExpanded ? 'SHOW_LESS_LABEL' : 'SHOW_MORE_LABEL', {
        lng: 'root',
        ns: authNamespaces.termsOfService,
      })} ${EVENT_TEXT.LINK}`,
    });
  };

  return (
    <Typography component="div" color={tosError && !tosContent.optional ? 'ink-error' : ''} variant="smallprint">
      {optInLabel && tosContent?.type === TERMS_OF_SERVICE_TYPE.MARKETING ? (
        <div
          ref={wrapperRef}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(optInLabel) }}
          css={marketingOptinLinkStyles}
        />
      ) : (
        tosContent.primaryTexts.map((text, index) => (
          <Fragment key={index}>
            <Trans t={t} i18nKey={text}>
              {tosContent?.links?.map(({ link, key, label, eventText }) => (
                <CustomLink
                  href={updateUrlWithLocale(link, eventText, uiLocale)}
                  key={key}
                  target="_blank"
                  tracking={{
                    linkText: eventText,
                    linkLocation: getEventLocation(
                      flowType,
                      AUTH_STATUS_BASED_PAGE_VIEWS[flowStatus.status]?.page_content_name,
                    ),
                  }}
                  cssStyles={[commonLinkStyles, disableForm ? disabledLinkStyles : '']}
                  ariaLabel={t(label, { ns: authNamespaces.termsOfService })}
                  outbound={true}
                />
              ))}
            </Trans>
            {/* When there secondary text, show more will be shown after primary text inline and if show more is clicked
            then secondary text will be shown in next line (alternative of using <br></br>*/}
            {!tosContent.showMoreExpandedText && <Typography></Typography>}
          </Fragment>
        ))
      )}

      {tosContent.showMoreExpandedText && (
        <>
          {!isTosExpanded && (
            <ExpandCollapseLink
              disableForm={disableForm}
              isTosExpanded={isTosExpanded}
              handleExpandCollapse={handleExpandCollapse}
            />
          )}
          <Collapse in={isTosExpanded} timeout={COLLAPSE_TRANSITION_TIMEOUT}>
            <Trans t={t} i18nKey={tosContent.showMoreExpandedText}>
              {tosContent?.showMoreExpandedTextSequence?.map(({ key, type, link, label, eventText }, index) => {
                /**
                 * Render the expanded text based on the type whether it is bold text or link which are inside tags like <0></0>,<1><1> etc.
                 * Normal paragraph texts are rendered as <Typography> with variant as smallprint which is parent of this component
                 * */
                if (type === 'boldText') {
                  return (
                    <Typography
                      variant="headline-smallest"
                      color={tosError ? 'ink-error' : ''}
                      sx={{ my: 1 }}
                      key={`${type}-${index}`}
                    ></Typography>
                  );
                }
                return (
                  <CustomLink
                    href={link}
                    key={`${key}-${index}`}
                    tracking={{
                      linkText: eventText,
                      linkLocation: getEventLocation(
                        flowType,
                        AUTH_STATUS_BASED_PAGE_VIEWS[flowStatus.status]?.page_content_name,
                      ),
                    }}
                    outbound={true}
                    cssStyles={[commonLinkStyles, disableForm ? disabledLinkStyles : '']}
                    ariaLabel={t(label as string, { ns: authNamespaces.termsOfService })}
                  />
                );
              })}
            </Trans>
          </Collapse>
          {isTosExpanded && (
            <Typography sx={{ my: 1 }} color={tosError && !tosContent.optional ? 'ink-error' : ''} variant="smallprint">
              <ExpandCollapseLink
                disableForm={disableForm}
                isTosExpanded={isTosExpanded}
                handleExpandCollapse={handleExpandCollapse}
              />
            </Typography>
          )}
        </>
      )}
    </Typography>
  );
};

const CustomCheckbox = ({
  disableForm,
  tosError,
  tosContent,
  onTosCheckBoxChange,
  optInLabel,
  spacingStyles,
  pageFlow = '',
}: CustomCheckboxProps) => {
  const { t } = useTranslation([tosContent.nameSpace, authNamespaces.termsOfService, authNamespaces.common]);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (tosError && !tosContent.optional) {
      trackError({
        error_code: ERROR_CODES.CLIENT_ERROR,
        error_type: ERROR_TYPE.CLIENT_SIDE_VALIDATION,
        error_message: t(tosContent.errorMessage ?? '', { lng: 'root' }),
        error_reason: tosContent.errorMessage ?? '',
        error_location: pageFlow,
      });
    }
  }, [tosError, tosContent.optional]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onTosCheckBoxChange(tosContent.type, e.target.checked);
    trackClick({
      link_location: getEventLocation(pageFlow, EVENT_TEXT.CHECKBOX),
      link_text: removeHtmlTags(t(tosContent.primaryTexts[0], { ns: tosContent.nameSpace })), //add  {lng:root} once the translations are updated
    });
  };

  const showTosError = tosError && !tosContent.optional;

  return (
    <>
      <FormControlLabel
        sx={spacingStyles}
        disabled={disableForm}
        css={checkboxLabelStyles}
        control={
          <Checkbox
            size="small"
            sx={{ my: 0 }}
            data-testid={`${tosContent.type}-checkbox`}
            id={tosContent.type}
            checked={checked}
            onChange={handleChange}
            name={tosContent.type}
            {...(showTosError && {
              inputProps: {
                'aria-describedby': tosContent.ariaDescribedBy,
              },
            })}
          />
        }
        label={
          <GetLabelForCheckbox
            tosContent={tosContent}
            disableForm={disableForm}
            tosError={tosError}
            optInLabel={optInLabel}
            pageFlow={pageFlow}
          />
        }
        data-testid={`${tosContent.type}-label`}
      />
      {showTosError && (
        <FormHelperText id={tosContent.ariaDescribedBy} sx={{ mt: 4 }}>
          <SvgIcon>
            <SvgError data-testid="tos-error-icon" />
          </SvgIcon>
          <Typography css={errorTextStyles}>
            {tosContent.errorMessage && t(tosContent.errorMessage, { ns: authNamespaces.termsOfService })}
          </Typography>
        </FormHelperText>
      )}
    </>
  );
};

const TermsOfService = ({
  disableForm,
  tosError,
  onTosError,
  onTosChange,
  onMarketingOptInChange,
  showMarketingOptIn = false,
  showTos = false,
  pageFlow = '',
}: TermsOfServiceProps) => {
  const { uiLocale } = useContext(AppContext);
  const { authCallbackResponse } = useContext(AuthContext);
  const optInLabel = authCallbackResponse?.response?.optInLabel;
  const [tosCheckBoxList, setTosCheckBoxList] = useState(
    getCheckBoxListWithInitialValues(uiLocale, showTos, showMarketingOptIn),
  );

  useEffect(() => {
    if (tosError) {
      // Updating the showError state of all tos checkboxes depending on the tosError prop
      setTosCheckBoxList((prev) => updateAllTosErrorValues(prev, true));
      // Call the onTosError callback with false to reset the tosError as false after updating the showError state for all tos checkboxes
      onTosError && onTosError(false);
    }
  }, [tosError, onTosError]);

  const onTosCheckBoxChange = (type: string, checkBoxValue: boolean) => {
    // Update the value of the checkbox that was clicked
    const updatedTosValues = getUpdatedTosValues(tosCheckBoxList, type, checkBoxValue);

    setTosCheckBoxList(updatedTosValues);

    // Check if all required tos are accepted
    const allRequiredTosAccepted = checkIfAllRequiredTosAccepted(updatedTosValues);

    if (onTosChange) {
      onTosChange(allRequiredTosAccepted);
    }

    if (showMarketingOptIn && onMarketingOptInChange && type === TERMS_OF_SERVICE_TYPE.MARKETING) {
      onMarketingOptInChange(checkBoxValue);
    }
  };

  return (
    <>
      {tosCheckBoxList.map((tosData, checkboxNumber) => {
        return (
          <FormControl key={tosData.type} disabled={disableForm} error={!tosData.optional && tosData.showError}>
            <CustomCheckbox
              disableForm={disableForm}
              onTosCheckBoxChange={onTosCheckBoxChange}
              tosError={!!tosData.showError}
              tosContent={tosData}
              spacingStyles={{ mt: checkboxNumber === 0 ? 6 : 4 }}
              optInLabel={optInLabel}
              pageFlow={pageFlow}
            />
          </FormControl>
        );
      })}
    </>
  );
};

export default TermsOfService;
