import { ErrorWithAlertMessages, GlobalErrorMessages, NameErrorMessages } from 'types';
import { EVENT_TEXT } from './analytics';
import { AUTH_ERROR_CODES, AUTH_STATUS, CUSTOM_AUTH_STATUS } from './auth';
import { ELEMENT_TYPES } from './elements';
import { AUTODESK_HOMEPAGE, CONTACT_SUPPORT, LEARN_MORE, TROUBLESHOOTING_GUIDE } from './links';

// Time in minutes
const SIGN_IN_UNAVAILABLE_TIME = 15;

const EMAIL_ERRORS = {
  // Account not found in Identity DB
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  // When a submitted email is invalid
  INVALID_REQUEST: 'INVALID_REQUEST',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_EMAIL_FORMAT: 'INVALID_EMAIL_FORMAT',
  // When an empty email is submitted to API bypassing UI checks
  EMAIL_MISSING: 'EMAIL_MISSING',
  // Server error while sending an OTP
  OTP_SENDING_FAILURE: 'OTP_SENDING_FAILURE',
  // When OTP send attempts are exceeded
  EXCEEDED_SEND_ATTEMPTS: 'EXCEEDED_SEND_ATTEMPTS',
  // Account is inactive
  ACCOUNT_INACTIVE: 'ACCOUNT_INACTIVE',
  // Account is restricted for create account (e.g. service account, restricted email domain, etc.)
  RESTRICTED_EMAIL: 'RESTRICTED_EMAIL',
  // Account is restricted for sign in (e.g. service account, restricted email domain, etc.)
  RESTRICTED_EMAIL_SIGN_IN: 'RESTRICTED_EMAIL_SIGN_IN',
  // Account is being deleted and not recoverable
  ACCOUNT_IS_BEING_DELETED: 'ACCOUNT_IS_BEING_DELETED',
  // Account is queued for deletion and is recoverable
  ACCOUNT_QUEUED_FOR_DELETION: 'ACCOUNT_QUEUED_FOR_DELETION',
  // An account with this Email already exists while creating an account
  DUPLICATE_EMAIL: 'DUPLICATE_EMAIL',
  // Access blocked for this particular email
  LOGIN_DISABLED: 'LOGIN_DISABLED',
};

const PASSWORD_ERRORS = {
  INVALID_ACCOUNT: 'INVALID_ACCOUNT',
  INVALID_USER_CREDENTIALS: 'INVALID_USER_CREDENTIALS',
  PASSWORD_MISSING: 'PASSWORD_MISSING',
  PASSWORD_DISALLOWED_BY_POLICY: 'PASSWORD_DISALLOWED_BY_POLICY',
  PASSWORDS_MUST_MATCH: 'PASSWORDS_MUST_MATCH',
  CONFIRM_THE_NEW_PASSWORD: 'CONFIRM_THE_NEW_PASSWORD',
  PASSWORD_CONTAINS_EMAIL: 'PASSWORD_CONTAINS_EMAIL',
  PASSWORD_PREVIOUSLY_USED: 'PASSWORD_PREVIOUSLY_USED',
};

const OTP_ERRORS = {
  INVALID_OTP_FORMAT: 'INVALID_OTP_FORMAT',
  INVALID_OTP: 'INVALID_OTP',
  EXPIRED_OTP: 'EXPIRED_OTP',
  EXCEEDED_SEND_ATTEMPTS: 'EXCEEDED_SEND_ATTEMPTS',
  EXCEEDED_OTP_ATTEMPTS: 'EXCEEDED_OTP_ATTEMPTS',
  FIELD_REQUIRED: 'FIELD_REQUIRED',
  OTP_SENDING_FAILURE: 'OTP_SENDING_FAILURE',
  OTP_CHECKING_FAILURE: 'OTP_CHECKING_FAILURE',
  WRONG_OTP: 'WRONG_OTP',
  REQUEST_FAILED: 'REQUEST_FAILED',
  OTP_ATTEMPTS_LIMIT: 'OTP_ATTEMPTS_LIMIT',
  OTP_MISSING: 'OTP_MISSING',
};

const GLOBAL_ERRORS = {
  INVALID_ACTION_ID: 'INVALID_ACTION_ID',
  INVALID_ACTION: 'INVALID_ACTION',
  UNEXPECTED_ERROR_OCCURRED: 'UNEXPECTED_ERROR_OCCURRED',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  FORBID_ENTRY: 'FORBID_ENTRY',
  SSO_LOGIN_ERROR: 'SSO_LOGIN_ERROR',
};

const ERROR_CATEGORIES = {
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS',
  INVALID_REQUEST: 'INVALID_REQUEST',
  INVALID_ACTION_ID: 'INVALID_ACTION_ID',
  UNEXPECTED_ERROR_OCCURRED: 'UNEXPECTED_ERROR_OCCURRED',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  ACCOUNT_VALIDATION_ERROR: 'ACCOUNT_VALIDATION_ERROR',
  HCAPTCHA_REQUIRED: 'HCAPTCHA_REQUIRED',
  ACCOUNT_ALREADY_EXISTS: 'ACCOUNT_ALREADY_EXISTS',
};

const ERROR_WITH_ALERT_MESSAGES: ErrorWithAlertMessages = {
  [CUSTOM_AUTH_STATUS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT]: {
    HEADER: 'TOO_MANY_ATTEMPTS',
    BODY: 'MAX_RETRIES_EMAIL_ERROR_DESCRIPTION',
    TIME: SIGN_IN_UNAVAILABLE_TIME,
  },
  [CUSTOM_AUTH_STATUS.EMAIL_RESEND_MAX_ATTEMPTS_LIMIT]: {
    HEADER: 'RESENDS_LIMIT_REACHED',
    BODY: 'RESENDS_LIMIT_REACHED_ERROR_DESCRIPTION',
    TIME: SIGN_IN_UNAVAILABLE_TIME,
  },
  // For MFA Email resend limit
  [AUTH_ERROR_CODES.SERVER_ERROR]: {
    HEADER: 'RESENDS_LIMIT_REACHED',
    BODY: 'RESENDS_LIMIT_REACHED_ERROR_DESCRIPTION',
    TIME: SIGN_IN_UNAVAILABLE_TIME,
  },
  // For MFA when both Email and TOTP are locked
  [AUTH_ERROR_CODES.NO_USABLE_DEVICES]: {
    HEADER: 'UNABLE_TO_SIGN_IN',
    BODY: 'FOR_SECURITY_SIGN_IN_IS_UNAVAILABLE',
    TIME: SIGN_IN_UNAVAILABLE_TIME,
  },
  // For MFA general error when status is MFA_FAILED
  [AUTH_STATUS.MFA_FAILED]: {
    HEADER: 'UNABLE_TO_SIGN_IN',
    BODY: 'FOR_SECURITY_SIGN_IN_IS_UNAVAILABLE',
    TIME: SIGN_IN_UNAVAILABLE_TIME,
  },
  [CUSTOM_AUTH_STATUS.SIGN_IN_REQUEST_EXPIRED]: {
    HEADER: 'SIGN_IN_REQUEST_EXPIRED',
    BODY: 'SIGNED_IN_TO_PRODUCT_DESCRIPTION',
    TIME: SIGN_IN_UNAVAILABLE_TIME,
  },
  // For Create Account flow when Email already exists and Login is disabled
  [CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED]: {
    HEADER: 'ACCESS_BLOCKED',
    BODY: 'ACCESS_WITH_THIS_EMAIL_IS_RESTRICTED',
  },
};

const GLOBAL_ERROR_ACTIONS = {
  REAUTHORIZE: 'REAUTHORIZE',
};

const GLOBAL_ERROR_MESSAGES: GlobalErrorMessages = {
  [CUSTOM_AUTH_STATUS.FORBID_ENTRY]: {
    HEADER: 'UNABLE_TO_ACCESS_LINK',
    BODY: 'FORBID_ENTRY_ERROR_DESCRIPTION',
    LINK: {
      URL: AUTODESK_HOMEPAGE,
      OUTBOUND: true,
      EVENT_TEXT: EVENT_TEXT.AUTODESK_HOME_PAGE,
    },
  },
  [CUSTOM_AUTH_STATUS.SESSION_ENDED]: {
    HEADER: 'SESSION_ENDED',
    BODY: 'SESSION_ENDED_DESCRIPTION',
    ACTION_ELEMENT: {
      TEXT: 'TRY_AGAIN',
      TYPE: ELEMENT_TYPES.BUTTON,
      ACTION: GLOBAL_ERROR_ACTIONS.REAUTHORIZE,
      ID: 'try-again-btn',
    },
  },
  [CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR]: {
    HEADER: 'INTERNAL_SERVER_ERROR',
    BODY: 'INTERNAL_SERVER_ERROR_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.PAGE_NOT_FOUND]: {
    HEADER: 'PAGE_NOT_FOUND',
    BODY: 'PAGE_NOT_FOUND_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.REQUEST_ERROR]: {
    HEADER: 'REQUEST_ERROR',
    BODY: 'REQUEST_ERROR_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR]: {
    HEADER: 'SAML_ASSERTION_ERROR',
    BODY: 'SAML_ASSERTION_ERROR_DESCRIPTION',
    LINK: {
      URL: TROUBLESHOOTING_GUIDE,
      OUTBOUND: true,
      EVENT_TEXT: EVENT_TEXT.TROUBLESHOOTING_GUIDE,
    },
  },
  [CUSTOM_AUTH_STATUS.DOMAIN_NOT_FOUND]: {
    HEADER: 'DOMAIN_NOT_FOUND_ERROR',
    BODY: 'DOMAIN_NOT_FOUND_ERROR_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.CONNECTION_NOT_FOUND]: {
    HEADER: 'CONNECTION_NOT_FOUND_ERROR',
    BODY: 'CONNECTION_NOT_FOUND_ERROR_DESCRIPTION',
    LINK: {
      URL: CONTACT_SUPPORT,
      OUTBOUND: true,
      EVENT_TEXT: EVENT_TEXT.CONTACT_SUPPORT,
    },
  },
  [CUSTOM_AUTH_STATUS.DOMAIN_CONNECTION_MISMATCH]: {
    HEADER: 'DOMAIN_CONNECTION_MISMATCH_ERROR',
    BODY: 'DOMAIN_CONNECTION_MISMATCH_ERROR_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.DOMAIN_DISABLED]: {
    HEADER: 'DOMAIN_DISABLED_ERROR',
    BODY: 'DOMAIN_DISABLED_ERROR_DESCRIPTION',
    LINK: {
      URL: LEARN_MORE,
      OUTBOUND: true,
      EVENT_TEXT: EVENT_TEXT.LEARN_MORE,
    },
  },
  [CUSTOM_AUTH_STATUS.USER_DEACTIVATED]: {
    HEADER: 'USER_DEACTIVATED_ERROR',
    BODY: 'USER_DEACTIVATED_ERROR_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.JIT_DISABLED]: {
    HEADER: 'JIT_DISABLED_ERROR',
    BODY: 'JIT_DISABLED_ERROR_DESCRIPTION',
  },
  [CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO]: {
    HEADER: 'UNABLE_TO_SIGN_IN_SSO_ERROR',
    BODY: 'UNABLE_TO_SIGN_IN_SSO_ERROR_DESCRIPTION',
    LINK: {
      URL: CONTACT_SUPPORT,
      OUTBOUND: true,
      EVENT_TEXT: EVENT_TEXT.CONTACT_SUPPORT,
    },
  },
};

const SIGN_IN_WITH_DIFF_ACCOUNT_STATUSES = [
  CUSTOM_AUTH_STATUS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT,
  CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED,
];

const NAME_ERRORS: NameErrorMessages = {
  firstName: {
    FIELD_REQUIRED: 'FIRST_NAME_REQUIRED',
    EXCEEDED_MAX_LENGTH: 'FIRST_NAME_EXCEEDS_CHAR_LIMIT',
    CONTAINS_UNSUPPORTED_CHARACTERS: 'FIRST_NAME_CONTAINS_UNSUPPORTED_CHARACTERS',
    MUST_CONTAIN_ONE_LETTER: 'FIRST_NAME_MUST_CONTAIN_LETTER',
    CONTAINS_BLOCKED_WORDS: 'FIRSTNAME_RESTRICTED_WORD',
  },
  lastName: {
    FIELD_REQUIRED: 'LAST_NAME_REQUIRED',
    EXCEEDED_MAX_LENGTH: 'LAST_NAME_EXCEEDS_CHAR_LIMIT',
    CONTAINS_UNSUPPORTED_CHARACTERS: 'LAST_NAME_CONTAINS_UNSUPPORTED_CHARACTERS',
    MUST_CONTAIN_ONE_LETTER: 'LAST_NAME_MUST_CONTAIN_LETTER',
    CONTAINS_BLOCKED_WORDS: 'LASTNAME_RESTRICTED_WORD',
  },
};

const NAME_SERVER_ERRORS = {
  FIRSTNAME_RESTRICTED_WORD: 'FIRSTNAME_RESTRICTED_WORD',
  LASTNAME_RESTRICTED_WORD: 'LASTNAME_RESTRICTED_WORD',
};

const LOGIN_REQUIRED = 'login_required';

const ESSO_ERROR_STATUS = [
  CUSTOM_AUTH_STATUS.CONNECTION_NOT_FOUND,
  CUSTOM_AUTH_STATUS.DOMAIN_CONNECTION_MISMATCH,
  CUSTOM_AUTH_STATUS.DOMAIN_NOT_FOUND,
  CUSTOM_AUTH_STATUS.DOMAIN_DISABLED,
  CUSTOM_AUTH_STATUS.JIT_DISABLED,
  CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR,
  CUSTOM_AUTH_STATUS.USER_DEACTIVATED,
  CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO,
];

export {
  EMAIL_ERRORS,
  ERROR_CATEGORIES,
  ERROR_WITH_ALERT_MESSAGES,
  ESSO_ERROR_STATUS,
  GLOBAL_ERROR_ACTIONS,
  GLOBAL_ERROR_MESSAGES,
  GLOBAL_ERRORS,
  LOGIN_REQUIRED,
  NAME_ERRORS,
  NAME_SERVER_ERRORS,
  OTP_ERRORS,
  PASSWORD_ERRORS,
  SIGN_IN_WITH_DIFF_ACCOUNT_STATUSES,
};
