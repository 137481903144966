import {
  LOCALE_MAP_CONTACT_SUPPORT_PASSWORD_POLICY_AUTODESK_HOMEPAGE,
  LOCALE_MAP_ESSO_ERRORS,
  BASE_LOCALES as LOCALE_MAP_PRIVACY_STATEMENT,
  LOCALE_MAP_TERMS_OF_USE,
} from 'configs/languageMappings';
import { EVENT_TEXT } from './analytics';
import { LINK_LOCALISATION_PATTERN } from './common';

const urlTypeToConfig: Record<string, { localeMap: Record<string, string>; defaultLocale: string; pattern: string }> = {
  [EVENT_TEXT.GENERAL_PRIVACY_STATEMENT_LINK]: {
    localeMap: LOCALE_MAP_PRIVACY_STATEMENT,
    defaultLocale: '',
    pattern: LINK_LOCALISATION_PATTERN.APPEND_LOCALE,
  },
  [EVENT_TEXT.GENERAL_TERMS_OF_USE_LINK]: {
    localeMap: LOCALE_MAP_TERMS_OF_USE,
    defaultLocale: 'en',
    pattern: LINK_LOCALISATION_PATTERN.REPLACE_DEFAULT_LOCALE,
  },
  [EVENT_TEXT.LOCAL_PRIVACY_STATEMENT_LINK]: {
    localeMap: LOCALE_MAP_PRIVACY_STATEMENT,
    defaultLocale: '',
    pattern: LINK_LOCALISATION_PATTERN.APPEND_LOCALE,
  },
  [EVENT_TEXT.LOCAL_TERMS_OF_USE_LINK]: {
    localeMap: LOCALE_MAP_TERMS_OF_USE,
    defaultLocale: 'en',
    pattern: LINK_LOCALISATION_PATTERN.REPLACE_DEFAULT_LOCALE,
  },
  [EVENT_TEXT.LEARN_MORE]: {
    localeMap: LOCALE_MAP_ESSO_ERRORS,
    defaultLocale: 'ENU',
    pattern: LINK_LOCALISATION_PATTERN.REPLACE_DEFAULT_LOCALE,
  },
  [EVENT_TEXT.TROUBLESHOOTING_GUIDE]: {
    localeMap: LOCALE_MAP_ESSO_ERRORS,
    defaultLocale: 'ENU',
    pattern: LINK_LOCALISATION_PATTERN.REPLACE_DEFAULT_LOCALE,
  },
  [EVENT_TEXT.CONTACT_SUPPORT]: {
    localeMap: LOCALE_MAP_CONTACT_SUPPORT_PASSWORD_POLICY_AUTODESK_HOMEPAGE,
    defaultLocale: '',
    pattern: LINK_LOCALISATION_PATTERN.INSERT_LOCALE_IN_URL,
  },
  [EVENT_TEXT.PASSWORD_POLICY_LINK]: {
    localeMap: LOCALE_MAP_CONTACT_SUPPORT_PASSWORD_POLICY_AUTODESK_HOMEPAGE,
    defaultLocale: '',
    pattern: LINK_LOCALISATION_PATTERN.INSERT_LOCALE_IN_URL,
  },
  [EVENT_TEXT.AUTODESK_HOME_PAGE]: {
    localeMap: LOCALE_MAP_CONTACT_SUPPORT_PASSWORD_POLICY_AUTODESK_HOMEPAGE,
    defaultLocale: '',
    pattern: LINK_LOCALISATION_PATTERN.INSERT_LOCALE_IN_URL,
  },
};

export { urlTypeToConfig };
