import { LINK_LOCALISATION_PATTERN, LOCALE_MAPPING, OAUTH_PARAMS, UI_LOCALE_COOKIE } from 'appConstants/common';

import { urlTypeToConfig } from 'appConstants/language';
import BrowserLanguageCodeMappings from 'configs/browserLanguageMappings';
import { LanguageCodeMappings } from 'configs/languageMappings';
import { getQueryParam, isIdsdkFlow, isLogoutFlow, isSAMLErrorFlow } from './commonUtils';
import { getAndRemoveUiLocaleFromIdsdkState } from './idsdkUtils';
import { getBrowserLang } from './langUtils';

export const getUiLocale = (locales: string): string => {
  const languageCodes = locales.split(' ');
  const validLocaleCode = languageCodes.find((code) => LanguageCodeMappings[code.replace('-', '_').toLowerCase()]);
  const updatedUiLocale = validLocaleCode ?? getFallbackLocale(locales, LOCALE_MAPPING.LANGUAGE) ?? getBrowserLang();
  return updatedUiLocale.replace('-', '_');
};

export const getFallbackLocale = (locales: string, mapping: string): string | null => {
  const languageCodes = locales.split(' ');
  const primaryLanguageCodes = languageCodes.map((code) => code.replace('-', '_').split('_')[0].toLowerCase());
  const selectedMapping = mapping === LOCALE_MAPPING.LANGUAGE ? LanguageCodeMappings : BrowserLanguageCodeMappings;
  const fallbackLanguage = primaryLanguageCodes.find((code) => selectedMapping[code]);

  return fallbackLanguage ? selectedMapping[fallbackLanguage] : null;
};

export const setUiLocaleCookie = (name: string, value: string) => {
  const UI_LOCALE_COOKIE_EXPIRATION_MS = 10 * 60 * 1000;
  const expires = new Date(Date.now() + UI_LOCALE_COOKIE_EXPIRATION_MS).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/; Secure; SameSite=Strict`;
};

export const getUiLocaleFromCookie = () => {
  return (
    document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith(`${UI_LOCALE_COOKIE}=`))
      ?.split('=')[1] ?? ''
  );
};

export const updateUrlWithLocale = (url: string, urlType: string, uiLocale: string): string => {
  const config = urlTypeToConfig[urlType];
  if (!config) return url;
  const { localeMap, defaultLocale, pattern } = config;
  const localeToAppend = localeMap[uiLocale] ?? defaultLocale;

  if (!localeToAppend) return url;

  switch (pattern) {
    case LINK_LOCALISATION_PATTERN.APPEND_LOCALE:
      return `${url}-${localeToAppend}`;
    case LINK_LOCALISATION_PATTERN.REPLACE_DEFAULT_LOCALE:
      return url.replace(defaultLocale, localeToAppend);
    case LINK_LOCALISATION_PATTERN.INSERT_LOCALE_IN_URL:
      return localeToAppend === 'cn' ? url.replace('.com', '.com.cn') : url.replace('.com', `.com/${localeToAppend}`);
    default:
      return url;
  }
};

export const getInitialLocale = (): string => {
  let configuredLocale = '';
  if (isIdsdkFlow()) {
    configuredLocale = getAndRemoveUiLocaleFromIdsdkState();
  } else if (isLogoutFlow()) {
    configuredLocale = getQueryParam(OAUTH_PARAMS.UI_LOCALES) ?? '';
  } else if (isSAMLErrorFlow()) {
    configuredLocale = getUiLocaleFromCookie();
  }

  const uiLocale = configuredLocale ? getUiLocale(configuredLocale) : getBrowserLang();
  return uiLocale.replace('-', '_');
};
