import {
  AUTH_STATUS_BASED_PAGE_VIEWS,
  CHECK_ANALYTICS_SCRIPT_LOAD_INTERVAL_MS,
  CHECK_ANALYTICS_SCRIPT_LOAD_TRIES,
  EVENT_TEXT,
  EVENT_TYPE,
  FLOW_NAME,
  FLOW_STATUS,
  PAGE_SITE_NAME,
  STATUS_NOT_TO_BE_TRACKED,
} from 'appConstants/analytics';
import { AUTH_STATUS, CUSTOM_AUTH_STATUS } from 'appConstants/auth';
import { URL_PARAMS } from 'appConstants/common';
import { TFunction } from 'i18next';
import obs from 'logging/observability/observability';
import authNamespaces from 'nameSpaces/authNameSpaces';
import {
  AnalyticsStatusData,
  AnalyticsTrackingParams,
  GtagErrorData,
  GtagEventClickData,
  GtagPageViewData,
} from 'types';
import { PasswordHintObject } from 'types/PasswordHint';
import { checkInviteUser, getFlowId } from './commonUtils';
import { getResendDataFromLocalStorage } from './otpScreenUtils';

let isAnalyticsScriptLoaded = false;
const queuedEvents: Array<AnalyticsTrackingParams> = [];

/* Run a check every 500ms to see if the analytics script is loaded (Max 4s)
 * This is done to ensure that the events are fired only after the analytics script is loaded
 * Analytics script is loaded at last, in order to improve app's performance and reduce render delay
 */
const checkIfAnalyticsScriptLoaded = () => {
  if (window.gtag) {
    isAnalyticsScriptLoaded = true;
    return;
  }
  let checkTries = 0;
  const intervalID = setInterval(() => {
    // Fire all the queued events once the analytics script is loaded and clear the interval
    if (window.gtag) {
      queuedEvents.forEach((event) => {
        /* TS complains about window.gtag being possibly undefined, even though we are checking for it above
         * so had to add an extra condition to check if its available
         */
        window.gtag && window.gtag('event', event.eventType, event.trackingData);
      });
      queuedEvents.length = 0;
      isAnalyticsScriptLoaded = true;
      clearInterval(intervalID);
    }
    checkTries++;
    // Try checking if the analytics script is loaded for 4s
    if (checkTries === CHECK_ANALYTICS_SCRIPT_LOAD_TRIES) {
      clearInterval(intervalID);
      /*
       * Setting isAnalyticsScriptLoaded to true so that individual events can fire when window.gtag is available
       * If the script is not loaded within 4s, the events fired after 4s will be lost, till the time the script is loaded
       * This can only happen in case the network is too slow to download the analytics scripts
       */
      isAnalyticsScriptLoaded = true;
    }
  }, CHECK_ANALYTICS_SCRIPT_LOAD_INTERVAL_MS);
};

const publishEvent = (trackingParams: AnalyticsTrackingParams) => {
  try {
    if (isAnalyticsScriptLoaded && window.gtag) {
      // Don't track UI Analytics events in New Relic
      obs.ignoreInteraction();
      window.gtag('event', trackingParams.eventType, trackingParams.trackingData);
    } else {
      queuedEvents.push(trackingParams);
    }
  } catch (error) {
    /**noop */
  }
};

const trackPageView = (params: GtagPageViewData) => {
  publishEvent({
    eventType: EVENT_TYPE.PAGE_VIEW,
    trackingData: {
      page_site_name: PAGE_SITE_NAME,
      page_locale: params.page_locale?.toLocaleLowerCase() ?? '',
      page_language_code: params.page_locale ? params.page_locale.slice(0, 2).toLocaleLowerCase() : '',
      page_content_name: params.page_content_name?.toLocaleLowerCase() ?? '',
      flow_name: params?.flow_name?.toLocaleLowerCase() ?? '',
      flow_client_id: params.flow_client_id ?? '',
      flow_id: params.flow_id?.toString() ?? '',
      send_to: window.idp?.env?.constants?.GOOGLE_ANALYTICS_ID,
      flow_status: params.flow_status?.toLocaleLowerCase() ?? '',
    },
  });
};

const trackClick = (params: GtagEventClickData) => {
  publishEvent({
    eventType: EVENT_TYPE.LINK_CLICK,
    trackingData: {
      link_location: params.link_location?.toLocaleLowerCase() ?? '',
      page_site_name: PAGE_SITE_NAME,
      link_text: params.link_text?.toLocaleLowerCase() ?? '',
      link_url: params?.link_url ?? '',
      outbound: params.outbound ? params.outbound : false,
      send_to: window.idp?.env?.constants?.GOOGLE_ANALYTICS_ID,
    },
  });
};

const trackError = (params: GtagErrorData) => {
  publishEvent({
    eventType: EVENT_TYPE.PAGE_ERROR,
    trackingData: {
      error_location: params.error_location?.toLocaleLowerCase() ?? '',
      page_site_name: PAGE_SITE_NAME,
      error_type: params.error_type?.toLocaleLowerCase() ?? '',
      error_code: params.error_code ?? '',
      error_reason: params.error_reason?.toLocaleLowerCase() ?? '',
      error_message: params.error_message?.toLocaleLowerCase() ?? '',
      send_to: window.idp?.env?.constants?.GOOGLE_ANALYTICS_ID,
    },
  });
};

const createParams = (analyticsData: AnalyticsStatusData): GtagPageViewData => {
  const baseParams: GtagPageViewData = {
    ...AUTH_STATUS_BASED_PAGE_VIEWS[analyticsData.flowStatus],
    page_locale: analyticsData.uiLocale,
    flow_client_id: analyticsData.clientID ?? '',
    flow_id: getFlowId(),
  };
  const resendData = getResendDataFromLocalStorage();
  // If the flow_name is '', set it to analyticsData.flowType
  if (baseParams.flow_name === '') {
    baseParams.flow_name =
      resendData?.flowType?.toLocaleLowerCase().replace(' ', '_') ?? analyticsData.flowType.replace('_', ' ');
  }

  const url = new URL(window.location.href);
  const isIdsdkFlow = url.searchParams.get(URL_PARAMS.IDSDK_DESKTOP_LOGOUT)?.toLowerCase() === 'true';
  const inviteUserFlow = checkInviteUser(analyticsData.uiPrompts);

  switch (analyticsData.flowStatus) {
    case AUTH_STATUS.OTP_REQUIRED:
      baseParams.page_content_name = baseParams.page_content_name + ' - ' + analyticsData.selectedDeviceType;
      break;
    case CUSTOM_AUTH_STATUS.LOGOUT:
      baseParams.flow_name = isIdsdkFlow ? FLOW_NAME.IDSDK : FLOW_NAME.LOGOUT;
      break;
    case AUTH_STATUS.EMAIL_VERIFICATION_REQUIRED:
      baseParams.flow_name = inviteUserFlow ? FLOW_NAME.INVITE : FLOW_NAME.CREATE_ACCOUNT;
      break;
    case AUTH_STATUS.USER_INFO_REQUIRED:
      if (inviteUserFlow) {
        baseParams.flow_name = FLOW_NAME.INVITE;
        baseParams.flow_status = FLOW_STATUS.INITIATE;
      } else {
        baseParams.flow_name = FLOW_NAME.CREATE_ACCOUNT;
        baseParams.flow_status = FLOW_STATUS.IN_PROGRESS;
      }
      break;
  }

  return baseParams;
};

const trackPageViewBasedOnStatus = (analyticsData: AnalyticsStatusData): void => {
  if (
    analyticsData.flowStatus !== '' &&
    !STATUS_NOT_TO_BE_TRACKED.includes(analyticsData.flowStatus) &&
    !analyticsData.isCCPA
  ) {
    trackPageView(createParams(analyticsData));
  }
};

const getPasswordErrorText = (hint: PasswordHintObject, t: TFunction) => {
  return t(hint.text, { lng: 'root', ns: authNamespaces.password, numberOfCharacters: hint.numberOfCharacters });
};

const getPasswordErrorForTealium = (hints: PasswordHintObject[], t: TFunction) => {
  let errorMessage = `${EVENT_TEXT.MISSING}: `;
  const inValidHints = hints.filter((hint: PasswordHintObject) => !hint.isValid);
  inValidHints.forEach((hint: PasswordHintObject, index: number) => {
    const errorText = getPasswordErrorText(hint, t);
    if (index === inValidHints.length - 2) {
      errorMessage += `${errorText} and `;
    } else if (index === inValidHints.length - 1) {
      errorMessage += errorText;
    } else {
      errorMessage += `${errorText}, `;
    }
  });
  return errorMessage;
};

export {
  checkIfAnalyticsScriptLoaded,
  getPasswordErrorForTealium,
  trackClick,
  trackError,
  trackPageView,
  trackPageViewBasedOnStatus,
};
